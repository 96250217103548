import React, { Component } from "react"
import styled from "styled-components"
import Helmet from "react-helmet"

import { Container, getHPadding } from "./Container"
import { Section } from "./Section"
import twitterIcon from "../images/twitter.svg"
import facebookIcon from "../images/facebook.svg"

const baseSize = 16
const upToDateWidth = 320
const twitterWidth = 430

const twitterUrl = "https://twitter.com/AtseSolutions"
const facebookUrl = "https://www.facebook.com/ATSESolutions/"

const getPadding = () => {
  let padding = 0
  let maxIterate = 0
  while (maxIterate < 3) {
    maxIterate += 1
    const screenWidth = padding * 2 + upToDateWidth + twitterWidth
    const actualPadding = getHPadding(screenWidth)
    if (actualPadding === padding) {
      break
    } else {
      padding = actualPadding
    }
  }
  return padding
}
const hPadding = getPadding()

const SectionStyled = styled(Section)`
  && {
    padding-top: 0;
  }
`

const ContainerStyled = styled(Container)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`

const SideTwitterContainer = styled.div`
  flex: 1 0 ${upToDateWidth / baseSize}rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  @media all and (min-width: ${(hPadding * 2 + upToDateWidth + twitterWidth) /
      baseSize}rem) {
    margin-bottom: 0;
  }
`

const UpToDateTitle = styled.h3`
  color: rgba(35, 36, 103, 1);
  font-weight: 600;
`

const UpToDateContent = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const UpToDateText = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`

const TwitterContainer = styled.div`
  flex: 0 0 ${twitterWidth / baseSize}rem;
  overflow-y: auto;
  height: auto;
  @media all and (min-width: ${(hPadding * 2 + upToDateWidth + twitterWidth) /
      baseSize}rem) {
    height: ${450 / baseSize}rem;
    overflow-y: scroll;
  }
`

export class TwitterTimeline extends Component {
  componentDidMount() {
    if (typeof window !== "undefined" && window.twttr !== undefined) {
      window.twttr.widgets.load(document.getElementById("twitterWidget"))
    }
  }
  render() {
    const genSocial = (icon, name, url, space) => {
      return (
        <a
          href={url}
          title={name}
          style={space ? { marginRight: "1.5rem" } : {}}
        >
          <img src={icon} title={name} alt={name} />
        </a>
      )
    }
    return (
      <SectionStyled>
        <ContainerStyled>
          <SideTwitterContainer>
            <UpToDateContent>
              <UpToDateTitle>Stay up-to-date with us?</UpToDateTitle>
              <UpToDateText>Follow us on</UpToDateText>
              <span>
                {genSocial(facebookIcon, "Facebook", facebookUrl, true)}
                {genSocial(twitterIcon, "Twitter", twitterUrl)}
              </span>
            </UpToDateContent>
          </SideTwitterContainer>
          <TwitterContainer>
            <a
              id="twitterWidget"
              className="twitter-timeline"
              data-width="480"
              data-height="480"
              data-dnt="true"
              data-theme="light"
              data-link-color="#88aaff"
              data-tweet-limit="9"
              data-chrome="nofooter noborders noscrollbar transparent"
              href="https://twitter.com/AtseSolutions?ref_src=twsrc%5Etfw"
            >
              Tweets by ATSE
            </a>
          </TwitterContainer>
          <Helmet>
            <script
              async
              src="https://platform.twitter.com/widgets.js"
              charSet="utf-8"
              type="text/javascript"
              id="twitter-widget"
            />
          </Helmet>
        </ContainerStyled>
      </SectionStyled>
    )
  }
}

export default TwitterTimeline
