import React from "react"
import styled from "styled-components"

const AboutSectionStyled = styled.div.attrs({
  className: "uk-section",
})``

const AboutTitleContainerStyled = styled.div.attrs({
  className: "uk-container uk-margin",
})``

const AboutTitleStyled = styled.h2.attrs({
  className:
    "uk-margin-auto uk-width-2-3@l uk-width-5-6@m uk-width-5-6@s uk-text-center",
})`
  color: #232467;
  font-weight: 600;
`

const AboutContentContainerStyled = styled.div.attrs({
  className: "uk-container uk-margin",
})`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`

const AboutContent1Styled = styled.div.attrs({
  className: "",
})`
  flex: 1 1 18.9375rem;
  max-width: 33.9375rem;
  font-size: 1.3rem;
  text-align: center;
  padding: 1rem;
  margin-top: 1rem;
`

const AboutContent2Styled = styled.div.attrs({
  className: "",
})`
  flex: 1 1 18.9375rem;
  max-width: 28.75rem;
  text-align: justify;
  padding: 1rem;
  margin-top: 1rem;
`

const ThreePillarsContainerStyled = styled.div.attrs({
  className: "uk-container uk-margin",
})`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
`

const ThreePillarStyled = styled.div.attrs({
  className: "",
})`
  flex: 1 1 18.5rem;
  max-width: 22.5rem;
  text-align: justify;
  padding: 1.5rem;
  margin-top: 1rem;
`

const ThreePillarTitleStyled = styled.h2.attrs({
  className: "",
})`
  color: #f57c01;
  font-weight: 600;
`

const ThreePillarDescStyled = styled.p.attrs({
  className: "",
})``

export const About = ({ data }) => {
  const APillar = (i) => (
    <ThreePillarStyled>
      <ThreePillarTitleStyled>
        {data.threePillars[i].title}
      </ThreePillarTitleStyled>
      <ThreePillarDescStyled>{data.threePillars[i].desc}</ThreePillarDescStyled>
    </ThreePillarStyled>
  )
  return (
    <AboutSectionStyled>
      <AboutTitleContainerStyled>
        <AboutTitleStyled>{data.title}</AboutTitleStyled>
      </AboutTitleContainerStyled>
      <AboutContentContainerStyled>
        <AboutContent1Styled
          dangerouslySetInnerHTML={{
            __html: data._1.replace(/\n/g, "<br />"),
          }}
        />
        <AboutContent2Styled
          dangerouslySetInnerHTML={{
            __html: data._2.replace(/\n/g, "<br />"),
          }}
        />
      </AboutContentContainerStyled>
      <ThreePillarsContainerStyled>
        {APillar(0)}
        {APillar(1)}
        {APillar(2)}
      </ThreePillarsContainerStyled>
    </AboutSectionStyled>
  )
}
