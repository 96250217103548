import React from "react"
import { graphql } from "gatsby"

import { Header } from "../components/Header"
import { Layout } from "../components/layout"
import { Hero } from "../components/hero"
import { About } from "../components/about"
import { Products } from "../components/products"
import { Services } from "../components/services"
import { Contact } from "../components/Contact"
import { Footer } from "../components/Footer"
import { TwitterTimeline } from "../components/TwitterTimeline"

const IndexPage = (props) => {
  const page = props.data.allDataJson.edges[0].node.pages.home
  return (
    <Layout meta={page.meta} loadUikit={true}>
      <Header />
      <Hero data={page.hero} />
      <About data={page.about} />
      <Products data={page.products} />
      <Services data={page.services} />
      <Contact data={page.contact} />
      <TwitterTimeline />
      <Footer />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          pages {
            home {
              meta {
                url
                title
                description
                imageRootUrl
                image
              }
              hero {
                title
                subTitle
                backgroundImage {
                  childImageSharp {
                    fluid(maxWidth: 1440, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              about {
                title
                _1
                _2
                threePillars {
                  title
                  desc
                }
              }
              products {
                title
                cards {
                  title
                  desc
                  url
                  image {
                    childImageSharp {
                      fluid(maxWidth: 360, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              services {
                title
                repairMaintenance {
                  title
                  desc
                  image {
                    childImageSharp {
                      fluid(maxWidth: 370, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  link
                }
                calibration {
                  title
                  desc
                  image {
                    childImageSharp {
                      fluid(maxWidth: 370, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  link
                }
                swHwDevelopment {
                  title
                  desc
                  image {
                    childImageSharp {
                      fluid(maxWidth: 650, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  link
                }
                projectManagement {
                  title
                  desc
                  image {
                    childImageSharp {
                      fluid(maxWidth: 1440, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              contact {
                title
                home {
                  desc
                  icon {
                    childImageSharp {
                      fixed(width: 32) {
                        ...GatsbyImageSharpFixed_tracedSVG
                      }
                    }
                  }
                }
                email {
                  icon {
                    childImageSharp {
                      fixed(width: 32) {
                        ...GatsbyImageSharpFixed_tracedSVG
                      }
                    }
                  }
                  image {
                    childImageSharp {
                      fixed(width: 151, height: 20) {
                        ...GatsbyImageSharpFixed_tracedSVG
                      }
                    }
                  }
                  emailAddEncoded
                }
                phone {
                  desc
                  icon {
                    childImageSharp {
                      fixed(width: 32) {
                        ...GatsbyImageSharpFixed_tracedSVG
                      }
                    }
                  }
                }
                map {
                  location {
                    lat
                    lng
                    z
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`
