import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const TitleStyled = styled.div.attrs({
  className: "uk-text-center uk-flex uk-flex-column uk-flex-center",
})``

const H1Styled = styled.h1.attrs({
  className: "",
})`
  color: white;
  font-weight: 400;
`

export const PStyled = styled.p`
  color: white;
  font-weight: 300;
`

export const Title = ({ title, subTitle, ...props }) => (
  <TitleStyled {...props}>
    <H1Styled>{title}</H1Styled>
    <PStyled
      dangerouslySetInnerHTML={{ __html: subTitle.replace("\\n", "<br />") }}
    />
  </TitleStyled>
)

Title.propTypes = {
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node.isRequired,
}
