import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

const SectionStyled = styled.div.attrs({
  className: "uk-section",
})``

const TitleContainerStyled = styled.div.attrs({
  className: "uk-container uk-margin",
})``

const TitleStyled = styled.h1.attrs({
  className: "",
})`
  color: rgba(35, 36, 103, 1);
  font-weight: bold;
`

const RepairContainerStyled = styled.div.attrs({
  className: "uk-container uk-margin",
})`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
`

const RepairImageStyled = styled(Img).attrs({
  className: "",
})`
  flex: 1 1 18.5rem;
  max-width: 50.3125rem;
  @media screen and (max-width: 54.0625rem) {
    height: 16.25rem;
  }
`

const BodyStyled = styled.div.attrs({
  className: "",
})`
  flex: 1 1 28.875rem;
  max-width: 54.25rem;
  padding: 1.5rem;
`

const BodyTitleStyled = styled.h2.attrs({
  className: "",
})`
  margin: 0 0 1rem 0;
  color: #f57c01;
  font-weight: 600;
`

const BodyDescStyled = styled.p.attrs({
  className: "",
})`
  margin: 0 0 1rem 0;
`

const LearnMoreStyled = styled(Link).attrs({
  className: "",
})`
  background-color: #ffb300;
  color: white;
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 4px;
  opacity: 1;
  transition: opacity 0.3s;
  box-shadow: 1px 1px 2px gray;
  &:hover,
  &:active,
  &:focus {
    color: white;
    opacity: 0.5;
    transition: opacity 0.3s;
  }
`

const CalibrationContainerStyled = styled.div.attrs({
  className: "uk-container uk-margin",
})`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
`

const CalibrationImageStyled = styled(Img).attrs({
  className: "",
})`
  flex: 1 1 18.5rem;
  order: 2; /* Image on right side */
  max-width: 50.3125rem;
  @media screen and (max-width: 54.0625rem) {
    order: -1; /* Image on top instead of bottom */
    height: 16.25rem;
  }
`

const CalibrationBodyStyled = styled(BodyStyled).attrs({
  className: "",
})`
  text-align: right;
`

const SHDevContainerStyled = styled.div.attrs({
  className: "uk-container uk-margin",
})`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`

const SHDevImageStyled = styled(Img).attrs({
  className: "",
})`
  flex: 1 1 18.5rem;
  max-width: 35rem;
  @media screen and (max-width: 43rem) {
    max-width: 27.5rem;
  }
`

const SHDevBodyStyled = styled.div.attrs({
  className: "",
})`
  flex: 1 1 17.625rem;
  max-width: 33.25rem;
  padding: 1.5rem;
`

const PMSectionStyled = styled.div.attrs({
  className: "uk-section",
})`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  background: url(${(props) => props.backgroundImage.src}) top 50% right 40%
    no-repeat;
  min-height: 25rem;
`

const PMContainerStyled = styled.div.attrs({
  className: "uk-container uk-margin",
})`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`

const PMBodyStyled = styled.div.attrs({
  className: "",
})`
  flex: 1 1 17.625rem;
  max-width: 33.25rem;
  color: white;
  text-align: center;
`

const PMTitleStyled = styled.h2.attrs({
  className: "",
})`
  color: white;
  font-weight: 600;
`

const PMDescStyled = styled.p.attrs({
  className: "",
})`
  /* font-size: 2rem; */
  color: white;
  margin-bottom: 0;
`

export const Services = ({ data }) => {
  return (
    <>
      <SectionStyled id="services">
        <TitleContainerStyled>
          <TitleStyled>{data.title}</TitleStyled>
        </TitleContainerStyled>
        <RepairContainerStyled>
          <RepairImageStyled
            fluid={data.repairMaintenance.image.childImageSharp.fluid}
          />
          <BodyStyled>
            <BodyTitleStyled>{data.repairMaintenance.title}</BodyTitleStyled>
            <BodyDescStyled>{data.repairMaintenance.desc}</BodyDescStyled>
            <LearnMoreStyled to={data.repairMaintenance.link}>
              Learn More
            </LearnMoreStyled>
          </BodyStyled>
        </RepairContainerStyled>
        <CalibrationContainerStyled>
          <CalibrationImageStyled
            fluid={data.calibration.image.childImageSharp.fluid}
          />
          <CalibrationBodyStyled>
            <BodyTitleStyled>{data.calibration.title}</BodyTitleStyled>
            <BodyDescStyled>{data.calibration.desc}</BodyDescStyled>
            <LearnMoreStyled to={data.calibration.link}>
              Learn More
            </LearnMoreStyled>
          </CalibrationBodyStyled>
        </CalibrationContainerStyled>
        <SHDevContainerStyled>
          <SHDevImageStyled
            fluid={data.swHwDevelopment.image.childImageSharp.fluid}
          />
          <SHDevBodyStyled>
            <BodyTitleStyled>{data.swHwDevelopment.title}</BodyTitleStyled>
            <BodyDescStyled>{data.swHwDevelopment.desc}</BodyDescStyled>
            <LearnMoreStyled to={data.swHwDevelopment.link}>
              Learn More
            </LearnMoreStyled>
          </SHDevBodyStyled>
        </SHDevContainerStyled>
      </SectionStyled>

      <PMSectionStyled
        backgroundImage={data.projectManagement.image.childImageSharp.fluid}
      >
        <PMContainerStyled>
          <PMBodyStyled>
            <PMTitleStyled>{data.projectManagement.title}</PMTitleStyled>
            <PMDescStyled>{data.projectManagement.desc}</PMDescStyled>
          </PMBodyStyled>
        </PMContainerStyled>
      </PMSectionStyled>
    </>
  )
}
