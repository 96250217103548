import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { Container } from "./Container"
import { Section } from "./Section"
import { Button } from "./Button"
import "leaflet/dist/leaflet.css"
import markerSvg from "../images/markerWithShadow.svg"
import EmailLink from "./EmailLink"

const ContactContainer = styled(Container)`
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
`

const baseSize = 16
const height = 385
const getInTouchWidth = 360
const mapWidth = 300
const mapMaxWidth = 1200

const GetInTouch = styled.div`
  flex: 1 1 ${getInTouchWidth / baseSize}rem;
  margin-bottom: 2rem;
  @media all and (min-width: ${(getInTouchWidth + mapWidth) / baseSize}rem) {
    margin-bottom: 0;
  }
`

const GetInTouchTitle = styled.h3`
  color: rgba(35, 36, 103, 1);
  font-weight: 600;
  margin-bottom: 2.5rem;
`

const Map = styled.div`
  flex: 999 1 ${mapWidth / baseSize}rem;
  max-width: ${mapMaxWidth / baseSize}rem;
  height: ${height / 16}rem;
`

const Job = styled.div``

export class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.mapId = "contactMap"
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      import("leaflet").then((L) => {
        const location = this.props.data.map.location
        const createPopup = ({ lat, lng, z }) => {
          const createA = (href, text) => {
            const a = document.createElement("a")
            const aText = document.createTextNode(text)
            a.setAttribute("href", href)
            a.setAttribute("target", "_blank")
            a.appendChild(aText)
            return a
          }
          const wazeA = createA(
            `https://www.waze.com/ul?ll=${lat}%2C${lng}&navigate=yes&zoom=${z}`,
            "Waze",
          )
          const appleMapA = createA(
            `https://maps.apple.com/?daddr=${lat},${lng}&sll=${lat},${lng}&z=${z}`,
            "Apple Map",
          )
          const googleMapA = createA(
            `https://www.google.com/maps/dir/Current+Location/${lat},${lng}`,
            "Google Map",
          )
          const span = document.createElement("span")
          span.appendChild(document.createTextNode(this.props.data.map.title))
          span.appendChild(document.createElement("br"))
          span.appendChild(document.createTextNode("Direction: "))
          span.appendChild(wazeA)
          span.appendChild(document.createTextNode(" or "))
          span.appendChild(appleMapA)
          span.appendChild(document.createTextNode(" or "))
          span.appendChild(googleMapA)
          return span
        }
        const map = L.map(this.mapId, {
          scrollWheelZoom: false,
          tap: false,
        }).setView([location.lat, location.lng], location.z)
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map)
        const markerIcon = L.icon({
          iconUrl: markerSvg,
          // original size:     37x48
          // size after shadow: 40x52
          iconSize: [40, 52],
          iconAnchor: [19, 48],
          popupAnchor: [0, -48],
        })
        L.marker([location.lat, location.lng], {
          icon: markerIcon,
          opacity: 0.9,
        })
          .addTo(map)
          .bindPopup(createPopup(location))
          .openPopup()
      })
    }
  }
  render() {
    const contact = this.props.data
    const tdIconStyle = {
      border: "none",
      verticalAlign: "top",
      padding: "0 0 1.5rem 0",
      lineHeight: "1.3rem",
    }
    const tdDescStyle = {
      ...tdIconStyle,
      padding: "0 0 1.5rem 1rem",
    }
    return (
      <Section id="contact">
        <ContactContainer>
          <GetInTouch>
            <GetInTouchTitle>{contact.title}</GetInTouchTitle>
            <table style={{ margin: "0 0 1.5rem 0" }}>
              <tbody>
                <tr>
                  <td style={tdIconStyle}>
                    <Img fixed={contact.home.icon.childImageSharp.fixed} />
                  </td>
                  <td style={tdDescStyle}>
                    <div
                      style={{ margin: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: contact.home.desc.replace(/\n/g, "<br />"),
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      ...tdIconStyle,
                      verticalAlign: "middle",
                      lineHeight: "1rem",
                    }}
                  >
                    <Img fixed={contact.email.icon.childImageSharp.fixed} />
                  </td>
                  <td
                    style={{
                      ...tdDescStyle,
                      verticalAlign: "middle",
                      margin: 0,
                    }}
                  >
                    <EmailLink
                      image={contact.email.image.childImageSharp.fixed}
                      emailAddEncoded={contact.email.emailAddEncoded}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ ...tdIconStyle, paddingBottom: 0 }}>
                    <Img fixed={contact.phone.icon.childImageSharp.fixed} />
                  </td>
                  <td style={{ ...tdDescStyle, margin: 0, paddingBottom: 0 }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: contact.phone.desc.replace(/\n/g, "<br />"),
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <Job style={{ marginBottom: "1.5rem" }}>
              Looking for a job?
              <Button to="/careers">Join our team</Button>
            </Job>
          </GetInTouch>
          <Map id={this.mapId} />
        </ContactContainer>
      </Section>
    )
  }
}
