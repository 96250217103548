import styled from "styled-components"
import { Link } from "gatsby"

export const Button = styled(Link)`
  background-color: #ffb300cc;
  min-width: 145px;
  height: 36px;
  color: white;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  text-align: center;
  padding: 0 30px;
  line-height: 36px;
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  margin: 0 1rem;
  &:hover {
    background-color: #ffb300;
    color: white;
    text-decoration: none;
  }
`
