import React from "react"
import styled from "styled-components"

import { Title } from "./title"
import { FluidBackgroundImage } from "../FluidBackgroundImage"

const HeroSectionStyled = styled(FluidBackgroundImage).attrs({
  className: "uk-section uk-background-cover",
})``

const FlexHeroStyled = styled.div.attrs({
  className:
    "uk-container uk-flex uk-flex-center uk-flex-column uk-flex-middle",
})`
  /* 140px vertical margin */
  min-height: calc(450px - 140px);
  min-height: calc(100vh - 140px);
`

const FlexTitleStyled = styled(Title).attrs({
  className: "uk-width-2-3@l uk-width-5-6@m uk-width-5-6@s",
})``

export const Hero = ({ data }) => {
  return (
    <HeroSectionStyled fluid={data.backgroundImage.childImageSharp.fluid}>
      <FlexHeroStyled
      // uk-parallax="bgy: -200"
      >
        <FlexTitleStyled title={data.title} subTitle={data.subTitle} />
      </FlexHeroStyled>
    </HeroSectionStyled>
  )
}
