import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const StyledAnchor = styled.a`
  cursor: pointer;
  :focus,
  :active,
  :hover {
    opacity: 0.5;
  }
`

const mailTo = (emailAddEncoded) => {
  if (typeof window !== "undefined") {
    const emailAddress = atob(emailAddEncoded)
    window.location.href = atob("bWFp") + atob("bHRv") + ":" + emailAddress
  }
}

export default function EmailLink({ image, emailAddEncoded }) {
  return (
    <StyledAnchor
      onClick={() => {
        mailTo(emailAddEncoded)
      }}
    >
      <Img fixed={image} />
    </StyledAnchor>
  )
}
