import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

const SectionStyled = styled.div.attrs({
  className: "uk-section",
})`
  background-color: rgba(35, 36, 103, 0.8);
`

const TitleContainerStyled = styled.div.attrs({
  className: "uk-container uk-margin",
})``

const TitleStyled = styled.h1.attrs({
  className: "",
})`
  color: white;
  font-weight: bold;
`

const CardsContainerStyled = styled.div.attrs({
  className: "uk-container uk-margin",
})`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;
`

const CardStyled = styled.div.attrs({
  className: "",
})`
  flex: 1 1 18.5rem;
  max-width: 22.5rem;
  background-color: white;
  border-radius: 0.625rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  margin: 1rem 1rem !important;
`

const CardImageStyled = styled(Img).attrs({
  className: "",
})`
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
`

const CardBodyStyled = styled.div.attrs({
  className: "",
})`
  padding: 1.5rem;
`

const CardTitleStyled = styled.h4.attrs({
  className: "",
})`
  margin: 0 0 1rem 0;
  color: #f57c01;
  font-weight: 600;
  text-decoration: underline;
`

const LinkStyled = styled(Link)`
  :link,
  :visited {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.65);
    filter: brightness(1);
    transition: all 0.3s ease-in;
  }
  :hover,
  :focus,
  :active {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.65);
    filter: brightness(0.7);
    transition: all 0.3s ease-out;
  }
`

const CardDescStyled = styled.p.attrs({
  className: "",
})`
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
`

export const Products = ({ data }) => {
  const ACard = (i) => (
    <LinkStyled to={data.cards[i].url}>
      <CardStyled>
        <CardImageStyled fluid={data.cards[i].image.childImageSharp.fluid} />
        <CardBodyStyled>
          <CardTitleStyled>{data.cards[i].title}</CardTitleStyled>
          <CardDescStyled>{data.cards[i].desc}</CardDescStyled>
        </CardBodyStyled>
      </CardStyled>
    </LinkStyled>
  )
  return (
    <SectionStyled id="products">
      <TitleContainerStyled>
        <TitleStyled>{data.title}</TitleStyled>
      </TitleContainerStyled>
      <CardsContainerStyled>
        {ACard(0)}
        {ACard(1)}
        {ACard(2)}
      </CardsContainerStyled>
    </SectionStyled>
  )
}
